import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Library from './Library';
import Insights from './Insights';
import NewsRoom from './NewsRoom';
export default function ResourceIndex() {
  return (
    <>
      <Routes>
<Route path="/library" element={<Library />} />
<Route path="/insights" element={<Insights />} />
<Route path="/newsroom" element={<NewsRoom />} />
</Routes>
    </>
  )
}
