import React from 'react'
import AccountingIndex from './accounting/AccountingIndex'
import AdvisoryIndex from './Advisory/AdvisoryIndex'
import AuditIndex from './audit/AuditIndex'
import CoorporateFinance from './corporateFinance/CoorporateFinance'
import CorporateIndex from './corporateFinance/CorporateIndex'
import Taxationindex from './Taxation/Taxationindex'


export default function Service() {
  return (
    <>

      <AccountingIndex/>
      <AdvisoryIndex/>
      <AuditIndex/>
     <CorporateIndex/>
     <Taxationindex/>
    </>
  )
}
