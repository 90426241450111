import React from 'react'
import LfieImzi from './LfieImzi'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CureentOpenings from './CurrentOpenings';

export default function PeopleIndex() {
  return (
    <>

<Routes>

<Route path="/lifeImzi" element={<LfieImzi />} />
<Route path="/currentopenings" element={<CureentOpenings />} />
</Routes>
    </>
  )
}
