import React from 'react'
import './css/controlService.css'
import bp1 from '../../../static/bp1.avif'
import bp2 from '../../../static/bp2.avif'
import bp3 from '../../../static/bp3.avif'
import bp4 from '../../../static/bp4.avif'
export default function Buisnessprocess() {
  return (
    <div>
       <div>
       <div className='h-24  overflow-x-hidden bg-berry'>
            <h2 className='text-center text-white my-4 overline text-xl lg:text-3xl 'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Business Process
             Integration (BPI) 
             </h2>
        </div> 
<div className='flex flex-col lg:flex-row items-center justify-center bg-berry'>
<div className='w-full lg:w-1/2 my-10 mx-10'>
    <div class="grid grid-col-2 ">
    <div className='mx-10 thumb'data-aos="zoom-out"data-aos-delay="50"
 data-aos-duration="1000">
  <img className="w-full h-auto max-h-96 rounded-lg" 
    src={bp2} alt=""/>
</div>

  
    </div>
  </div>
  <div className='w-4.6 lg:w-1/2 my-10 mx-4'>

    <p className='mb-3 text-white first-line:uppercase text-xs sm:text-xs md:text-[16px] lg:text-[16px]
first-line:tracking-widest first-letter:text-md sm:first-letter:text-md md:first-letter:text-lg lg:first-letter:text-2xl first-letter:font-bold 
 first-letter:text-white
 first-letter:me-1 first-letter:float-start'data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
    Business Process Integration (BPI) 
    </p>
    <p className='text-left text-white text-[16px]'data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
    Involves connecting different systems, applications, and processes within an   
       organization to facilitate the smooth flow of information.
       This integration is crucial for enhancing operational efficiency,
        reducing costs, and ensuring harmonious collaboration across all business facets.
        
    </p>
    <p className='text-left text-white text-[16px] my-2'data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
    Our steps to achieve BPI include identification, assessment,
          design, implementation, and optimization of integrated business
           processes. The benefits of BPI include reduced costs, improved
            customer service, better decision-making, and enhanced customer experience.
    </p>
  </div>
</div>
    </div>
    </div>
  )
}
