import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AdvisoryService from './AdvisoryService';
import Buisness from './Buisness';
import Buisnessrocess from './Buisnessrocess';
import CorporateFinance from './CorporateFinance';
import Enterprise from './Enterprise';
import Policy from './Policy';
import Regulatory from './Regulatory';
import StaffRequirement from './StaffRequirement';
import ScrollToTop from '../../ScrollToTop';

export default function AdvisoryIndex() {
  return (
    <Routes>
   {/* <ScrollToTop/> */}
    <Route path="/advisoryService" element={<AdvisoryService />} />
    <Route path="/buisness" element={<    Buisness/>} />
    <Route path="/buisnessprocess" element={<Buisnessrocess />} />
    <Route path="/Finance" element={<CorporateFinance />} />
    <Route path="/enterprise" element={<Enterprise />} />
    <Route path="/policy" element={<Policy />} />
    <Route path="/regulatory" element={<Regulatory />} />
    <Route path="/staff" element={<StaffRequirement />} />
    </Routes>
  )
}
