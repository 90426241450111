import React from 'react'
import s1 from '../../../static/s1.avif'
import s2 from '../../../static/s2.jpg'
import s3 from '../../../static/s3.jpg'
import s4 from '../../../static/Recruitment.jpg'
export default function StaffRequirement() {
  return (
    <>
         <div className='h-24  overflow-x-hidden bg-fruit'>
            <h2 className='text-center text-white my-4 overline text-xl lg:text-3xl 'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Staff Recruitment Services For Clients</h2>
        </div> 
    <div className='flex flex-col lg:flex-row items-center justify-center
     bg-fruit'>
   
   <div className='mx-10 my-5 thumb'>
  <img className="w-full h-auto max-h-96 rounded-lg" data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000"
    src={s4} alt=""/>
</div>
    <div className='w-4.6 lg:w-1/2 my-10 mx-2 lg:mx-10 '>
      <h1 className='mb-3 text-white first-line:uppercase text-xs sm:text-xs md:text-[16px] lg:text-[16px]
first-line:tracking-widest first-letter:text-md sm:first-letter:text-md md:first-letter:text-lg lg:first-letter:text-2xl first-letter:font-bold 
 first-letter:text-white
 first-letter:me-1 first-letter:float-start'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Staff Recruitment Services for Clients</h1>
      <p className='text-[16px] text-white appear'data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000"> IMZI & IHCA excels in providing bespoke Staff Recruitment Services, dedicated to assisting our clients in building high-performing teams tailored to their unique organizational needs. Leveraging our expertise in talent acquisition, we streamline the recruitment process, ensuring that you attract, select, and onboard top-tier talent that aligns with your business objectives and values.
  
      </p>
      <p className=' text-[16px] text-white appear my-3'data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">Tailor your recruitment strategy to your specific requirements. We collaborate closely with clients to understand their organizational culture, workforce needs, and strategic goals, developing customized recruitment plans that yield optimal results.
  
  </p>
    </div>
  </div>
  </>
  )
}
