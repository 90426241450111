import React from 'react'
import ac1 from '../../static/newwsroom1.jpg';
export default function NewsRoom() {
  return (
    <div>
            <div className='h-24 overflow-x-hidden bg-berry'>
  <h2 className='text-center text-white my-4 overline text-xl lg:text-3xl '>NewsRoom</h2>
</div>
<div className='h-24 overflow-x-hidden bg-berry'>
  <h2 className='text-center text-white my-4  text-xl lg:text-2xl '>Our partner’s photograph has been featured in the London Business School’s achievement publication</h2>
</div>
<div className='flex items-center justify-center bg-berry drop-shadow-lg'>
  <div className='w-5/6 sm:w-3/4 md:w-2/3 lg:w-2/3 xl:w-2/3 bg-berry rounded-lg mt-4 mb-4'>
    <figure className='relative bg-berry'>
      <a target="_blank" href="https://www.london.edu/news/lbs-open-executive-education-in-global-top-three?_cldee=MOo6zRXbfrAr8JdjesyDDmRgoJa3ZvXFHJutM_MD7VR8lLv99hlclk1qH4bjuikh&recipientid=contact-cc24df410808ed1196a80022483f942a-6def2397478b4b709486a2b05b5cc4c1&utm_source=ClickDimensions&utm_medium=email&utm_campaign=MC_BRBRAND_email_ClickDimensions_CIA&esid=8ddd780c-2214-ef11-96c1-0022483f942a" className=''>
        <img
          className='rounded-lg w-full my-3 cursor-pointer filter '
          src={ac1}
          alt="image description"
        />
      </a>

    </figure>
  </div>
</div>
    </div>
  )
}
