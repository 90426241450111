// Loading.js
import React from 'react';

const Loading = ({ loading }) => {
  return (
    <>
      {loading && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center
         bg-white bg-opacity-75
     z-50">
          <div className="w-24 h-24 border-t-4 border-blue-600 rounded-full animate-spin"></div>
          {/* You can customize the size, color, and animation as needed */}
        </div>
      )}
    </>
  );
};

export default Loading;
