import React from 'react'
import './css/Service.css'
import { Link } from 'react-router-dom'
import r1 from '../../static/r1.webp'
import r2 from '../../static/r2.webp'
import r3 from '../../static/r3.webp'
import r4 from '../../static/r4.webp'
import r5 from '../../static/r5.webp'
export default function OurService() {
  return (
    <>
    <div className='bg-berry'>
    <div className='h-24 overflow-x-hidden bg-berry'data-aos="zoom-in-up"data-aos-delay="50"
     data-aos-duration="1000">

  

    <h2 className='text-center my-4 lg:text-3xl text-xl overline text-white'>Our Services</h2>
</div>

   <div className='bg-berry'>
    <div className="service      bg-berry ">
  
    <div className="service-container  grid grid-cols-1 sm:grid-cols-3 md:grid-col-3  lg:grid-cols-5 my-28 lg:my-10
 
    "
    data-aos="zoom-in-down"data-aos-delay="50"
     data-aos-duration="1000"
    >
    <div className="service-card">
            <div className="service-content">
            <div className='text-3xl '>
                    <img className=' border border-amber-300 rounded-full
                     h-44 w-44  mx-3 justify-center align-middle text-center' 
  src={r1} alt="" srcset="" />
                </div>
                <p className='text-2xl my-10'>Audit & Assurance </p>
                <div className='mt-28 '>
               <a href="#_" class="relative inline-flex items-center
                px-3 py-2 overflow-hidden text-lg font-medium
                 text-black-500 border-2  hover:border-amber-500
                  rounded-full hover:text-black group hover:bg-amber-500">
<span class="absolute left-0 block w-full h-0 transition-all bg-cyan-500
 opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease"></span>

<Link to='/auditassurance' class="relative text-sm">Our services</Link>
</a>
</div>
            </div>
        </div>
        <div className="service-card">
            <div className="service-content">
            <div className='text-2xl '>
                    <img className=' border border-amber-300 rounded-full
                     h-44 w-44 mx-6 justify-center align-middle text-center'
 src={r2} alt="" srcset="" />
                </div>
                <p className='text-2xl text-white my-11'>Accounting & Business Process Outsourcing</p>
                <div className='mt-8'>
                <a href="#_" class="relative inline-flex items-center
                px-3 py-2 overflow-hidden text-lg font-medium
                 text-black-500 border-2  hover:border-amber-500
                  rounded-full hover:text-black group hover:bg-amber-500">
<span class="absolute left-0 block w-full h-0 transition-all bg-cyan-500
 opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease"></span>
<Link to='/accountingbuisness' class="relative text-sm">Our services</Link>
</a>
</div>
            </div>
        </div>
    
        <div className="service-card">
            <div className="service-content">
            <div className='text-3xl '>
                    <img className=' border border-amber-300 rounded-full
                     h-44 w-44  mx-3 justify-center align-middle text-center' 
  src={r3} alt="" srcset="" />
                </div>
                <p className='text-2xl my-10'>Taxation  <br/> Service</p>
                

                <div className='mt-28 '>
               <a href="#_" class="relative inline-flex items-center
                px-3 py-2 overflow-hidden text-lg font-medium
                 text-black-500 border-2  hover:border-amber-500
                  rounded-full hover:text-black group hover:bg-amber-500">
<span class="absolute left-0 block w-full h-0 transition-all bg-cyan-500
 opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease"></span>

<Link to='/taxationService' class="relative text-sm">Our services</Link>
</a>
</div>
            </div>
        </div>
        <div className="service-card">
            <div className="service-content">
            <div className='text-3xl '>
                    <img className=' border border-amber-300 rounded-full
                     h-44 w-44  mx-3 justify-center align-middle text-center' 
  src={r4} alt="" srcset="" />
                </div>
                <p className='text-2xl my-10'>Corporate Finance  </p>
                <div className='mt-28 '>
               <a href="#_" class="relative inline-flex items-center
                px-3 py-2 overflow-hidden text-lg font-medium
                 text-black-500 border-2  hover:border-amber-500
                  rounded-full hover:text-black group hover:bg-amber-500">
<span class="absolute left-0 block w-full h-0 transition-all bg-cyan-500
 opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease"></span>

<Link to='/corporatefinance' class="relative text-sm">Our services</Link>
</a>
</div>
            </div>
        </div>
        <div className="service-card">
            <div className="service-content">
            <div className='text-3xl '>
                    <img className=' border border-amber-300 rounded-full
                     h-44 w-44  mx-3 justify-center align-middle text-center' 
  src={r5} alt="" srcset="" />
                </div>
                <p className='text-2xl my-10'>Advisory Services  </p>
                <div className='mt-28 '>
               <a href="#_" class="relative inline-flex items-center
                px-3 py-2 overflow-hidden text-lg font-medium
                 text-black-500 border-2  hover:border-amber-500
                  rounded-full hover:text-black group hover:bg-amber-500">
<span class="absolute left-0 block w-full h-0 transition-all bg-cyan-500
 opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease"></span>

<Link to='/advisoryService' class="relative text-sm">Our services</Link>
</a>
</div>
            </div>
        </div>
      </div>
    </div>
    </div>
    </div>
    </>
  )
}