import React, { useState, useEffect } from 'react';

const Counter = ({ targetValue }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count < targetValue) {
      const interval = setInterval(() => {
        setCount((prevCount) => prevCount + 1);
      }, 100); // Change the interval duration (in milliseconds) for the speed of counting

      return () => {
        clearInterval(interval);
      };
    }
  }, [count, targetValue]);

  return (
    <div>
      <h1 className='text-5xl my-2 text-white justify-center align-middle text-center' data-aos="zoom-in"data-aos-delay="500"
     data-aos-duration="1000"> {count}+</h1>
    </div>
  );
};

export default Counter;
