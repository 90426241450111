
// import './App.css';
// import React, { useEffect } from 'react';
// import Header from './component/header/Header.jsx';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Home from '../src/component/homepage/Home.jsx'
// import Footer from './component/footer/Footer.jsx';
// import AboutIndex from './component/about/AboutIndex.jsx';
// import PeopleIndex from './component/People/PeopleIndex.jsx';
// import Service from './component/service/Service.jsx'
// import AddressandContact from './component/Contact/AddressandContact.jsx';
// import ContactIndex from './component/Contact/ContactIndex.jsx';
// import ResourceIndex from './component/Resouces/ResourceIndex.jsx';
// import PrivacyPolicy from './component/footer/PrivacyPolicy.jsx';
// import Disclaimer from './component/footer/Disclaimer.jsx';

// import AOS from 'aos';
// import 'aos/dist/aos.css';
// import ScrollToTop from './component/ScrollToTop.js';


// function App() {
//   useEffect(() => {
//     AOS.init();
//     // AOS.init({ disable: "mobile" });
//   }, []);
//   return (
//     <>
//     <Header/>
 
//     <AboutIndex/>
//   <Service/>
//     <PeopleIndex/>
//    <ResourceIndex/>
//     <ContactIndex/>
//     <Routes>
//       {/* <ScrollToTop/> */}
//   <Route path="/" element={<Home />} />
//   <Route path="/privacypolicy" element={<PrivacyPolicy />} />
//   <Route path="/disclaimer" element={<Disclaimer />} />
// </Routes>

// <Footer/>

//     </>
//   );
// }

// export default App;


import './App.css';
import React, { useEffect } from 'react';
import Header from './component/header/Header.jsx';
import { Routes, Route } from 'react-router-dom';
import Home from '../src/component/homepage/Home.jsx';
import Footer from './component/footer/Footer.jsx';
import AboutIndex from './component/about/AboutIndex.jsx';
import PeopleIndex from './component/People/PeopleIndex.jsx';
import Service from './component/service/Service.jsx';
import ContactIndex from './component/Contact/ContactIndex.jsx';
import ResourceIndex from './component/Resouces/ResourceIndex.jsx';
import PrivacyPolicy from './component/footer/PrivacyPolicy.jsx';
import Disclaimer from './component/footer/Disclaimer.jsx';

import AOS from 'aos';
import 'aos/dist/aos.css';
import ScrollToTop from './component/ScrollToTop.js';

function App() {
  useEffect(() => {
    AOS.init();
    // AOS.init({ disable: "mobile" });
  }, []);

  return (
    <>
      <ScrollToTop>
        <Header />

        <AboutIndex />
        <Service />
        <PeopleIndex />
        <ResourceIndex />
        <ContactIndex />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
        </Routes>

        <Footer />
      </ScrollToTop>
    </>
  );
}

export default App;