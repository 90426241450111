import React from 'react'
import { Link } from 'react-router-dom'
import './css/accounting.css'
import ac1 from '../../../static/ac1.webp'
import ac2 from '../../../static/r4.webp'
import ac3 from '../../../static/ac3.webp'
import ac4 from '../../../static/provident2.jpg'
export default function AccountingandBuisness() {
  return (
    <>
     <div className=' bg-berry '>
  <div className='h-24 bg-berry overflow-x-hidden '>
            <h2 className='text-center my-4 overline text-xl lg:text-3xl 
            text-white
            'data-aos="zoom-in"data-aos-delay="50"
            data-aos-duration="1000">Accounting & Business Process Outsourcing</h2>
        </div>
        <div class="p-1 flex flex-wrap items-center justify-center  ">
        <div class="flex justify-center items-center my-10 mx-10">

    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-10">

<Link to='/bookeeping'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
    <div class="w-full max-w-sm bg-white  rounded-lg shadow img-cards">
    <figure class="relative max-w-sm ">
  <a href="#" className='acnt'>
    <img class="rounded-lg  cursor-pointer h-96 w-96
    filter blur-0 hover:blur-sm"
     src={ac1}
     alt="image description"/>
  </a>
  <figcaption class="absolute px-4 text-xl  text-white bottom-6 text-reveal">
      <p className=''>Bookkeeping & Cloud Accounting Services</p>
  </figcaption>
</figure>
</div>
</Link>
<Link to='/buisnessoutsourcing'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">

<div class="w-full max-w-sm bg-white  rounded-lg shadow img-cards">
 <figure class="relative max-w-sm ">
<a href="#" className='acnt'>
 <img class="rounded-lg transition-all duration-300 cursor-pointer h-96 w-96
 filter blur-0 hover:blur-sm"
 src={ac2}
  alt="image description"/>
</a>
<figcaption class="absolute px-4 text-xl text-white bottom-6 text-reveal">
   <p>Business Process Outsourcing</p>
</figcaption>
</figure>
</div>
</Link>     
<Link to='/payrollHr'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">

    <div class="w-full max-w-sm bg-white  rounded-lg shadow img-cards">
    <figure class="relative max-w-sm ">
  <a href="#" className='acnt'>
    <img class="rounded-lg transition-all duration-300 cursor-pointer h-96 w-96
    filter blur-0 hover:blur-sm"
    src={ac3}
     alt="image description"/>
  </a>
  <figcaption class="absolute px-4 text-xl text-white bottom-6 text-reveal">
      <p>Payroll / HR Processing/Payroll Tax </p>
  </figcaption>
</figure>
</div>   
</Link>
<Link to='/provident'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
 <div class="w-full max-w-sm bg-white  rounded-lg shadow .img-cards">
    <figure class="relative max-w-sm ">
  <a href="#" className='acnt'>
    <img class="rounded-lg transition-all duration-300 cursor-pointer h-96 w-96
    filter blur-0 hover:blur-sm"
    src={ac4}
     alt="image description"/>
  </a>
  <figcaption class="absolute px-4 text-xl text-white bottom-6 text-reveal">
      <p>Provident Fund/Gratuity Fund Management</p>
  </figcaption>
</figure>
</div> 
</Link>
    
    </div>
</div>


        </div>
        </div>
    </>
  )
}
