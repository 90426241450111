import React from 'react'
import './css/OurVision.css'
import v1 from '../../static/v1.webp'
import v2 from '../../static/v2.avif'
import v3 from '../../static/v3.avif'
import v4 from '../../static/v4.avif'
export default function OurVision() {
  return (
    <>
          <div className='h-24 bg-cyan overflow-x-hidden'>
            <h2 className='text-center my-4 overline text-xl lg:text-3xl
            text-white'
            // data-aos="zoom-in-up"data-aos-delay="50"
            // data-aos-duration="1000"
            
            >Vision Statement</h2>
        </div>
  


<div className='flex flex-col lg:flex-row items-center justify-center bg-cyan'>
  <div className='w-full lg:w-1/2 my-10 mx-2 lg:mx-10'>
  
  <div className='mx-10 thumb'data-aos="zoom-in"data-aos-delay="50"
     data-aos-duration="1000">
  <img className="w-full h-auto max-h-96 rounded-lg" 
    src={v4} alt=""/>
</div>


  </div>
  <div className='w-4.6 lg:w-1/2  mx-12 lg:mx-10'data-aos="zoom-out"data-aos-delay="50"
     data-aos-duration="1000">

  <div class="flex flex-col justify-between p-4 leading-normal  text-reveal">
       <p className='text-2xl text-white'>IMZI & IHCA strives to:</p>
       <div>
        <ul class="space-y-4 text-left text-white">
            <li className='text-[16px] my-4  flex items-center space-x-3 rtl:space-x-reverse'>
            <svg class="flex-shrink-0 w-3.5 h-3.5 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
         </svg>
                
               <p className='text-[16px] text-justify'>Be a trustworthy and valued business partner.</p> </li>
               <li className='text-[16px] my-4 flex items-center space-x-3 rtl:space-x-reverse'>
            <svg class="flex-shrink-0 w-3.5 h-3.5 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
         </svg>
                
               <p className='text-[16px] text-justify'>Demonstrate passion for assisting clients in achieving their goals.</p> </li>
               <li className='text-[16px] my-4  flex items-center space-x-3 rtl:space-x-reverse'>
            <svg class="flex-shrink-0 w-3.5 h-3.5 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
         </svg>
                
               <p className='text-[16px] text-justify'>Embrace authenticity and inclusivity as integral elements of our identity.</p> </li>
               <li className='text-[16px] my-4 flex items-center space-x-3 rtl:space-x-reverse'>
            <svg class="flex-shrink-0 w-3.5 h-3.5 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
         </svg>
                
               <p className='text-[16px] text-justify'>Look beyond mere numerical figures.</p> </li>
        </ul>
       </div>
       </div>
  </div>
</div>
    </>
  )
}
