import React from 'react'
import './css/library.css'
export default function Library() {
  return (
   <>
               <div className='h-24 overflow-x-hidden bg-berry'>
  <h2 className='text-center text-white my-4 overline text-xl lg:text-3xl '>Library</h2>
</div>
    <div className='flex justify-center items-center my-10 '>

          <table>
        <tr className=''>
            <th>S.No</th>
            <th>Resource Title</th>
            <th>Website Link</th>
        
        </tr>
        <tr>
            <td>1</td>
            <td>Accounting Standard</td>
            <td><a target='blank' className='hover:underline' href="https://www.icab.org.bd/page/accounting-standards">https://www.icab.org.bd/page/accounting-standards</a></td>
        
        </tr>

        <tr>
            <td>2</td>
            <td>Auditing Standards</td>
            <td><a target='blank' className='hover:underline' href="https://www.icab.org.bd/page/auditing-standards">https://www.icab.org.bd/page/auditing-standards</a></td>
         
        </tr>
        <tr>
            <td>3</td>
            <td>ICAB Study Manual</td>
            <td><a target='blank' className='hover:underline' href="https://www.icab.org.bd/page/study-manuals">https://www.icab.org.bd/page/study-manuals</a></td>
       
        </tr>
        <tr>
            <td>4</td>
            <td>Finance Acts</td>
            <td><a target='blank' className='hover:underline' href="https://nbr.gov.bd/regulations/acts/finance-acts/eng">https://nbr.gov.bd/regulations/acts/finance-acts/eng</a></td>

        </tr>
        <tr>
            <td>5</td>
            <td>VAT Acts</td>
            <td><a target='blank' className='hover:underline' href="https://nbr.gov.bd/regulations/acts/vat-acts/eng">https://nbr.gov.bd/regulations/acts/vat-acts/eng</a></td>
        
        </tr>
        <tr>
            <td>6</td>
            <td>VAT Rules</td>
            <td><a target='blank' className='hover:underline' href="https://nbr.gov.bd/regulations/rules/vat-rules/eng">https://nbr.gov.bd/regulations/rules/vat-rules/eng</a></td>
        
        </tr>
        <tr>
            <td>7</td>
            <td>VAT Forms</td>
            <td><a target='blank' className='hover:underline' href="https://nbr.gov.bd/form/vat/vat-2012/eng">https://nbr.gov.bd/form/vat/vat-2012/eng</a></td>
        
        </tr>
        <tr>
            <td>8</td>
            <td>Customs Acts</td>
            <td><a target='blank' className='hover:underline' href="https://nbr.gov.bd/regulations/acts/customs-acts/eng">https://nbr.gov.bd/regulations/acts/customs-acts/eng</a></td>
        
        </tr>
        <tr>
            <td>9</td>
            <td>Customs Rules</td>
            <td><a target='blank' className='hover:underline' href="https://nbr.gov.bd/regulations/rules/customs-rules/eng">https://nbr.gov.bd/regulations/rules/customs-rules/eng</a></td>
        
        </tr>
        <tr>
            <td>10</td>
            <td>Income Tax Acts</td>
            <td><a target='blank' className='hover:underline' href="https://nbr.gov.bd/regulations/acts/income-tax-acts/eng">https://nbr.gov.bd/regulations/acts/income-tax-acts/eng</a></td>
        
        </tr>
        <tr>
            <td>11</td>
            <td>Income Tax Rules</td>
            <td><a target='blank' className='hover:underline' href="https://nbr.gov.bd/regulations/rules/income-tax-rules/eng">https://nbr.gov.bd/regulations/rules/income-tax-rules/eng</a></td>
        
        </tr>
        <tr>
            <td>12</td>
            <td>Income Tax Rules</td>
            <td><a target='blank' className='hover:underline' href="https://nbr.gov.bd/form/income-tax/eng">https://nbr.gov.bd/form/income-tax/eng</a></td>
        
        </tr>
        <tr>
            <td>13</td>
            <td>Excise Act</td>
            <td><a target='blank' className='hover:underline' href="https://nbr.gov.bd/regulations/acts/excise-acts/eng">https://nbr.gov.bd/regulations/acts/excise-acts/eng</a></td>
        
        </tr>
        <tr>
            <td>14</td>
            <td>Company Acts</td>
            <td><a target='blank' className='hover:underline' href="https://www.icsb.edu.bd/rules/act/companies-act-1994">https://www.icsb.edu.bd/rules/act/companies-act-1994</a></td>
        
        </tr>
        <tr>
            <td>15</td>
            <td>Securities Related Ordinance, Act, Rules and Regulations</td>
            <td><a target='blank' className='hover:underline' href="https://sec.gov.bd/home/lbook">https://sec.gov.bd/home/lbook</a></td>
        
        </tr>
        <tr>
            <td>16</td>
            <td>Latest Securities Laws</td>
            <td><a target='blank' className='hover:underline' href="https://www.sec.gov.bd/home/laws">https://www.sec.gov.bd/home/laws</a></td>
        
        </tr>
        <tr>
            <td>17</td>
            <td>Bank Related Regulations and Guidelines</td>
            <td><a target='blank' className='hover:underline' href="https://www.bb.org.bd/en/index.php/about/guidelist">https://www.bb.org.bd/en/index.php/about/guidelist</a></td>
        
        </tr>
        <tr>
            <td>18</td>
            <td>Bangladesh Bank Circulars</td>
            <td><a target='blank' className='hover:underline' href="https://www.bb.org.bd/en/index.php/mediaroom/circular">https://www.bb.org.bd/en/index.php/mediaroom/circular</a></td>
        
        </tr>
        <tr>
            <td>19</td>
            <td>Insurance Related Acts</td>
            <td><a target='blank' className='hover:underline' href="http://www.idra.org.bd/site/view/law/-">http://www.idra.org.bd/site/view/law/-</a></td>
        
        </tr>
        <tr>
            <td>20</td>
            <td>Alphabetical List of Laws of Bangladesh</td>
            <td><a target='blank' className='hover:underline' href="http://bdlaws.minlaw.gov.bd/laws-of-bangladesh-alphabetical-index.html">http://bdlaws.minlaw.gov.bd/laws-of-bangladesh-alphabetical-index.html</a></td>
        
        </tr>
    </table>
    </div>
    </> 
  )
}
