import React from 'react'
import AddressandContact from './AddressandContact'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import GoogleMap from './GoogleMap';
export default function ContactIndex() {
  return (
    <div>
            <Routes>
            <Route path="/getintouch" element={<AddressandContact />} />
            <Route path="/map" element={<GoogleMap />} />
</Routes>
       
    </div>
  )
}
