import React from 'react'
import regulatory from '../../../static/Regulatory service.jpg'
export default function Regulatory() {
  return (
    <div>
    <div className='bg-fruit'>
    <div className='h-24  overflow-x-hidden'>
            <h2 className='text-center text-white my-4 overline text-xl lg:text-3xl 'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">RJSC, BIDA & Other Regulatory Services</h2>
        </div> 
<div class="p-1 items-center justify-center  ">

<div className='mx-4 my-5 justify-center items-center appear '>


<p class="mb-3 text-white first-line:uppercase text-xs sm:text-xs md:text-[16px] lg:text-[16px]
first-line:tracking-widest first-letter:text-md sm:first-letter:text-md md:first-letter:text-lg lg:first-letter:text-2xl first-letter:font-bold 
 first-letter:text-white
 first-letter:me-1 first-letter:float-start"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000"> RJSC, BIDA & Other Regulatory Services

 </p>

<p class=" text-[16px] text-white"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000"> IMZI & IHCA is your reliable partner for Regulatory Services, 
  specializing in the efficient management of essential registrations and compliance procedures with entities
   such as the Registrar of Joint Stock Companies and Firms (RJSC), Bangladesh Investment Development Authority
    (BIDA), and other regulatory bodies. If you are considering starting a new business, we encourage you to contact  IMZI & IHCA today to learn more about our company formations support services. We would be happy to help you get your business started on the right foot. Our company formations support services include:

.</p>


<div class="thumbanail right h-72 ">
        <img src={regulatory} alt="" />
    </div>

<dl class="max-w-md text-white divide-y divide-gray-200  my-5 text-reveal">
<div class="flex flex-col pb-3">

<dd class="text-[16px] font-semibold "data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Choosing the right type of company structure</dd>
</div>
<div class="flex flex-col py-3">

<dd class="text-[16px] font-semibold"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Completing the necessary paperwork </dd>
</div>
<div class="flex flex-col py-3">

<dd class="text-[16px] font-semibold"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Registering your company </dd>
</div>
<div class="flex flex-col py-3">

<dd class="text-[16px] font-semibold">data-aos="zoom-in"data-aos-delay="50"
data-aos-duration="1000"Advice on corporate governance, compliance, and other aspects of running a business.</dd>
</div>
<div class="flex flex-col py-3">

<dd class="text-[16px] font-semibold"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Name clearance from RJSC</dd>
</div>
<div class="flex flex-col py-3">

<dd class="text-[16px] font-semibold"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">	Registration of any foreign company needs extra permission (apart from government registrar office).</dd>
</div>
</dl>




</div>
</div>

</div>
<div className='bg-fruit'>
<div class="p-1 items-center justify-center  ">

<div className='mx-4  justify-center items-center appear '>




<p class="mb-3 text-[16px] text-white  ">
Our dedicated team ensures that your business remains fully compliant with the ever-evolving regulatory landscape, allowing you to focus on your core operations with confidence. We offer services for
.</p>


<dl class="max-w-md text-white divide-y divide-gray-200  my-5 text-reveal">
<div class="flex flex-col pb-3">

<dd class="text-[16px] font-semibold ">RJSC Compliance:</dd>
</div>
<div class="flex flex-col py-3">

<dd class="text-[16px] font-semibold">BIDA Services: </dd>
</div>
<div class="flex flex-col py-3">

<dd class="text-[16px] font-semibold">License and Permit Management:</dd>
</div>


</dl>

</div>
</div>

</div>
</div>
  )
}
