import React from 'react'
import Flagship from '../../static/as7.webp'
export default function OurFlagship() {
  return (
   <>
             <div className='h-24  overflow-x-hidden bg-fruit'>
            <h2 className='text-center text-white my-4 overline text-xl lg:text-3xl 'data-aos="zoom-in"data-aos-delay="50"
     data-aos-duration="1000">Our Core Flagship Behavior</h2>
        </div> 
      
<div className='flex flex-col lg:flex-row items-center justify-center bg-fruit'>
  <div className='w-full lg:w-1/2 my-10 mx-10'>
    <div class="grid grid-col-2 ">
    <div className='mx-10 thumb'data-aos="zoom-in"data-aos-delay="50"
     data-aos-duration="1000">
  <img className="w-full h-auto max-h-96 rounded-lg" 
    src={Flagship} alt=""/>
</div>

  
    </div>
  </div>
  <div className='w-4.6 lg:w-1/2 my-10 mx-12 lg:mx-10 justify-end align-middle items-center'data-aos="zoom-in-up"data-aos-delay="50"
     data-aos-duration="1000">
  <ul class="space-y-4 text-left text-white">
            <li className='text-[16px] my-4 list-disc flex items-center
             space-x-3 rtl:space-x-reverse'>
         

                
               <p className='text-[16px] '> <strong>One Firm:</strong> Succeeding together as a united entity
               , sharing a common vision and values.</p> </li>
               <li className='text-[16px] my-4 flex items-center space-x-3 rtl:space-x-reverse'>

                
               <p className='text-[16px] '> <strong>Keep it Easy:</strong> Finding simplified solutions for complex challenges.</p> </li>
               <li className='text-[16px] my-4  flex items-center space-x-3 rtl:space-x-reverse'>

               <p className='text-[16px] '> <strong>Make it Happen:</strong> Focusing on achieving results and making them practical.</p> </li>
               <li className='text-[16px] my-4 flex items-center space-x-3 rtl:space-x-reverse'>

                
               <p className='text-[16px] '><strong>Be Accountable: </strong> Acknowledging both successes and failures.</p> </li>
               <li className='text-[16px] my-4 flex items-center space-x-3 rtl:space-x-reverse'>

                
               <p className='text-[16px] '><strong>Integrity:</strong> Respecting integrity and expecting the same in return.</p> </li>
        
        </ul>
  </div>
</div>

   </> 
  )
}
