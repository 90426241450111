import React, { useState, useEffect } from 'react';
import './css/Home.css';
import OurTeam from './OurTeam';
import OurService from './OurService';
import GetInTouch from './GetInTouch';
import Resouces from './Resouces';
import CircularProgressBar from './CircularProgressBar';

export default function Home() {
  const [showAlternateText, setShowAlternateText] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowAlternateText((prev) => !prev);
    }, 8500); // Toggle text every 8.5 seconds

    return () => clearInterval(interval);
  }, []);

  // Split the text into parts for each word
  const imziText = "IMZI".split("");
  const advisoryText = "Advisory".split("");
  const imanFirstName = "Iman".split("");
  const hasanText = "Hasan,".split("");
  const charteredText = "Chartered".split("");
  const accountantsText = "Accountants".split("");
  const ihcaText = "(IHCA)".split("");

  return (
    <>
      <div className="box h-80 flex items-center overflow-y-auto">
        <div className="flex items-end w-full text-reveal">
          <div
            className="text-center mx-20 md:mx-20"
            data-aos="fade-up"
            data-aos-delay="500"
            data-aos-duration="2000"
            data-aos-easing="ease-in-out"
          >
            <div className="text-cyan h-auto">
              <h2 className="text-white text-lg text-left w-auto sm:text-4xl md:max-w-md lg:text-2xl">
                {showAlternateText ? (
                  <>
                    {/* Iman Hasan */}
                    <div>
                      {imanFirstName.map((char, index) => (
                        <span key={index} style={{ animationDelay: `${index * 0.2}s` }}>
                          {char}
                        </span>
                      ))}
                      <span>&nbsp;</span> {/* Add space between words */}
                      {hasanText.map((char, index) => (
                        <span key={index} style={{ animationDelay: `${(imanFirstName.length + index) * 0.2}s` }}>
                          {char}
                        </span>
                      ))}
                    </div>

                    {/* Chartered Accountants (IHCA) */}
                    <div>
                      {charteredText.map((char, index) => (
                        <span key={index} style={{ animationDelay: `${index * 0.2}s` }}>
                          {char}
                        </span>
                      ))}
                      <span>&nbsp;</span> {/* Add space between words */}
                      {accountantsText.map((char, index) => (
                        <span key={index} style={{ animationDelay: `${(charteredText.length + index) * 0.2}s` }}>
                          {char}
                        </span>
                      ))}
                      <span>&nbsp;</span> {/* Add space between words */}
                      {ihcaText.map((char, index) => (
                        <span key={index} style={{ animationDelay: `${(charteredText.length + accountantsText.length + index) * 0.2}s` }}>
                          {char}
                        </span>
                      ))}
                    </div>
                  </>
                ) : (
                  <>
                    {/* IMZI Advisory */}
                    <span>
                      {imziText.map((char, index) => (
                        <span key={index} style={{ animationDelay: `${index * 0.2}s` }}>
                          {char}
                        </span>
                      ))}
                    </span>
                    <span>&nbsp;</span> {/* Add space between words */}
                    <span>
                      {advisoryText.map((char, index) => (
                        <span key={index} style={{ animationDelay: `${(imziText.length + index) * 0.2}s` }}>
                          {char}
                        </span>
                      ))}
                    </span>
                  </>
                )}
              </h2>
            </div>
            <p className="text-[16px] mt-3 text-white max-w-md text-left">
              Distinguished Premier Chartered Accountants and Advisors with Globally Renowned Expertise and Unrivaled Professional Insight.
            </p>
          </div>
        </div>
      </div>

      <OurTeam />
      <CircularProgressBar />
      <OurService />
      <Resouces />
      <GetInTouch />
    </>
  );
}
