import React from 'react'
import provident from '../../../static/Provident Fund.jpg'
export default function ProvidentFund() {
  return (
    <div>
               <div className='h-24  overflow-x-hidden bg-berry'>
            <h2 className='text-center text-white my-4 overline text-xl lg:text-3xl 'data-aos="zoom-in-"data-aos-delay="50"
 data-aos-duration="1000">Provident
             fund/Gratuity Fund Management</h2>
        </div> 
          <div className='bg-berry'>
 <div class="p-1 items-center justify-center  ">

<div className='mx-4  justify-center items-center appear '>

 
<p class="mb-3 text-white first-line:uppercase text-xs sm:text-xs md:text-[16px] lg:text-[16px]
first-line:tracking-widest first-letter:text-md sm:first-letter:text-md md:first-letter:text-lg lg:first-letter:text-2xl first-letter:font-bold 
 first-letter:text-white
 first-letter:me-1 first-letter:float-start"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000"> Provident fund/Gratuity fund management</p>
<p class="text-white  text-[16px] test-left"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
IMZI & IHCA as a leading provider of provident fund and gratuity fund management outsourcing services. We have a proven track record of helping businesses streamline their operations, improve efficiency, and reduce costs. We are confident that we can provide you with the expertise and resources you need to manage your provident fund and gratuity fund effectively and efficiently. We offer a wide range of services, including

</p>


 </div>
 </div>

    </div>
    <div className='bg-berry'>
 <div class="p-1 items-center justify-center  ">
 
<div className='mx-4  justify-center items-center appear '>

 
<div class="thumbanail right h-96 "data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
        <img src={provident} alt="" />
    </div>

<dl class="max-w-md nlack divide-y divide-gray-600  my-5 text-reveal text-white">
    <div class="flex flex-col pb-3">
       
        <dd class="text-[16px] font-semibold"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">Provident fund contribution processing</dd>
    </div>
    <div class="flex flex-col py-3">
        
        <dd class="text-[16px] font-semibold"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">Gratuity fund contribution processing</dd>
    </div>
    <div class="flex flex-col py-3">
       
        <dd class="text-[16px] font-semibold"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">Provident fund and gratuity fund investment management</dd>
    </div>
    <div class="flex flex-col py-3">
       
       <dd class="text-[16px] font-semibold"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">Provident fund and gratuity fund recordkeeping</dd>
   </div>
   <div class="flex flex-col py-3">
       
       <dd class="text-[16px] font-semibold"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">Provident fund and gratuity fund member communication</dd>
   </div>
   <div class="flex flex-col py-3">
       
       <dd class="text-[16px] font-semibold"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">Provident fund and gratuity fund compliance</dd>
   </div>
   
</dl>

<p class="text-white text-[16px]"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
We are committed to providing our clients with high-quality, scalable solutions that meet their evolving needs. We are also committed to building long-term partnerships with our clients and providing them with the support they need to succeed.


</p>
 </div>
 </div>

    </div>
    </div>
  )
}
