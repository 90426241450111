import React from 'react'
import { Link } from 'react-router-dom'
import IHCA from '../../static/IHCA.png'
import bglogo from '../../static/logoblue.png'
export default function Footer() {

  return (
    <>
      

      <footer class="bg-orange-50">
    <div class="mx-auto w-full max-w-screen-xl">
        <div className='flex flex-1 justify-between'>

        
        <a href="/" class="flex items-center mb-4 sm:mb-0 rtl:space-x-reverse my-0 lg:my-2">
  <img src={bglogo} class="h-8 lg:h-12 xl:h-16 mb-15  lg:mb-0 " alt="Flowbite Logo" />
  <img src={IHCA} class="h-8 lg:h-12 xl:h-16 mb-15 lg:mb-0" alt="Flowbite Logo" />
</a>

<Link to='/getintouch'  class="my-10 sm:my-10 d:my-10 lg:my-10 
relative inline-flex items-center justify-center
 px-6  overflow-hidden font-medium transition 
 duration-300 ease-out  group"  style={{"border-left"
 : "4px solid #0496C7 "}}>
<span class="absolute inset-0 flex items-center justify-center w-full h-full
 text-white duration-300 -translate-x-full bg-cyan group-hover:translate-x-0 ease">

</span>
<span class="absolute flex items-center justify-center w-full h-full 
 text-fruit transition-all duration-300 text-xl
 transform  ease">Get In Touch</span>
<span class="relative invisible">Get In Touch</span>
</Link>
            </div>
            <div className='border-t border-fruit my-1'></div>
      <div class="grid grid-cols-2 gap-8 px-4 
      py-6 lg:py-8 md:grid-cols-5">
   
        <div>
            <h2 class="mb-6 text-md font-bold
             text-cyan uppercase
             transition ease-in-out delay-150  hover:-translate-y-1 
              duration-300
             ">About Us</h2>
            <ul class="text-cyan  font-medium">
                <li class="mb-4 transition ease-in-out delay-150  hover:-translate-y-1 
              duration-300">
                    <Link  to="/whoweare" class=" hover:underline hover:text-amber-600">Who We are</Link>
                </li>
                <li class="mb-4 transition ease-in-out delay-150  hover:-translate-y-1 
              duration-300">
                    <Link to="/ourteam" class="hover:underline hover:text-amber-600">Our Team</Link>
                </li>
                <li class="mb-4 transition ease-in-out delay-150  hover:-translate-y-1 
              duration-300">
                    <Link to="/ourpurpose" class="hover:underline hover:text-amber-600">Our Purpose</Link>
                </li>
                <li class="mb-4 transition ease-in-out delay-150  hover:-translate-y-1 
              duration-300">
                    <Link to="/ourvision" class="hover:underline hover:text-amber-600">Our Vision</Link>
                </li>
                <li class="mb-4 transition ease-in-out delay-150  hover:-translate-y-1 
              duration-300">
                    <Link to="/ourflagship" class="hover:underline hover:text-amber-600">Our Flagship Behavior</Link>
                </li>
            </ul>
        </div>
        <div>
            <h2 class="mb-6 text-md font-bold
             text-cyan uppercase
             transition ease-in-out delay-150  hover:-translate-y-1 
              duration-300
             
            ">Services</h2>
            <ul class="text-cyan  font-medium">
                <li class="mb-4 transition ease-in-out delay-150  hover:-translate-y-1 
                    duration-300">
                    <Link to="/auditassurance" class=" hover:text-amber-500
                    
                    ">Audit & Assurance</Link>
                </li>
                <li class="mb-4 transition ease-in-out delay-150  hover:-translate-y-1 
                    duration-300 ">
                    <Link to="/accountingbuisness" class="hover:underline hover:text-amber-600">Accounting & Business Process Outsourcing</Link>
                </li>
                <li class="mb-4 transition ease-in-out delay-150  hover:-translate-y-1 
                    duration-300">
                    <Link to="/taxationService" class="hover:underline hover:text-amber-600">Taxation Service</Link>
                </li>
                <li class="mb-4 transition ease-in-out delay-150  hover:-translate-y-1 
                    duration-300">
                    <Link to="/corporatefinance" class="hover:underline hover:text-amber-600">
                        Corporate Finance </Link>
                </li>
                <li class="mb-4 transition ease-in-out delay-150  hover:-translate-y-1 
                    duration-300">
                    <Link to="/advisoryService" class="hover:underline hover:text-amber-600">
                    Advisory Services </Link>
                </li>
            </ul>
        </div>
        <div>
            <h2 class="mb-6 text-md font-bold
             text-cyan uppercase
             transition ease-in-out delay-150  hover:-translate-y-1 
              duration-300
             ">Resources</h2>
            <ul class="text-cyan  font-medium">
                <li class="mb-4 transition ease-in-out delay-150  hover:-translate-y-1 
                    duration-300">
                    <Link to="/insights" class="hover:underline hover:text-amber-600">Insights</Link>
                </li>
                <li class="mb-4 transition ease-in-out delay-150  hover:-translate-y-1 
                    duration-300">
                    <Link to="/newsroom" class="hover:underline hover:text-amber-600">Newsroom</Link>
                </li>
                <li class="mb-4 transition ease-in-out delay-150  hover:-translate-y-1 
                    duration-300">
                    <Link to="/library" class="hover:underline hover:text-amber-500">Library</Link>
                </li>
             
            </ul>
        </div>
        <div>
            <h2 class="mb-6 text-md font-bold
             text-cyan uppercase
             transition ease-in-out delay-150  hover:-translate-y-1 
              duration-300
             ">People</h2>
            <ul class="text-cyan  font-medium">
                <li class="mb-4 transition ease-in-out delay-150  hover:-translate-y-1 
                    duration-300">
                    <Link to="/lifeimzi" class="hover:underline hover:text-amber-500">Life at IMZI & IHCA </Link>
                </li>
                <li class="mb-4 transition ease-in-out delay-150  hover:-translate-y-1 
                    duration-300">
                    <Link to="/currentopenings" class="hover:underline hover:text-amber-500">Current Openings</Link>
                </li>
               
            </ul>
        </div>
        <div>
            <h2 class="mb-6 text-md font-bold
             text-cyan uppercase
             transition ease-in-out delay-150  hover:-translate-y-1 
              duration-300
            ">Contact Us</h2>
            <ul class="text-cyan font-medium
            ">
               
                <li class="mb-4 transition ease-in-out delay-150  hover:-translate-y-1 
                    duration-300">
                    <Link to="/getintouch" class="hover:underline hover:text-amber-500">Office Address & Contact Info</Link>
                </li>
                <li class="mb-4 transition ease-in-out delay-150  hover:-translate-y-1 
                    duration-300">
                    <Link to="/map" class="hover:underline hover:text-amber-500">Google Map</Link>
                </li>
                
            </ul>
        </div>
    </div>
    <div className='border-t border-fruit '></div>
    <div class="  px-4 py-2 bg-cyan-400  flex flex-wrap
      justify-start sm:justify-start md:justify-between lg:justify-between">
      <div class="flex  space-x-5  md:mt-0 mx-3 ">
            <a href="https://www.facebook.com/IMZI.ADVISORY/"  target='_blank' class="text-berry hover:text-gray-900 mt-3 
             
             ">
                  <svg class="w-7 h-6  " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 19">
                        <path fill-rule="evenodd" d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z" clip-rule="evenodd"/>
                    </svg>
                  <span class="sr-only">Facebook page</span>
              </a>
        
              <a href="https://www.linkedin.com/company/imzi-ca-advisory/"  target='_blank' class="text-berry mt-3 
               hover:text-gray-900 
               ">
                  <svg class="w-7 h-6 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 17">
                  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401m-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4"/>
                </svg>
                  <span class="sr-only">Twitter page</span>
              </a>
        
              <a href=" http://www.youtube.com/@IMZIQuest
" target='_blank' class="text-berry mt-3 
               hover:text-gray-900 
               ">
                  <svg class="w-7 h-6 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 17">
                  <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408z"/>
                </svg>
                  <span class="sr-only">Twitter page</span>
              </a>
            
        </div>  
        <div className='flex flex-wrap'>
     
        <Link to='/disclaimer' class="mx-4 mt-3  text-lg text-gray-700
         hover:text-black
         sm:text-center">Disclaimer
        </Link>
        <Link to='/privacypolicy' class="mx-4  mt-3 text-lg
         text-gray-700 hover:text-black
         sm:text-center">Privacy policy
        </Link>
        <p class="mx-4 text-lg  mt-3 
         text-gray-700 hover:text-black
         sm:text-center">2024 IMZI & IHCA. All rights reserved.
        </p>
        </div>
        
       
        
      </div>

    </div>
</footer>


    </>
  )
}