import React from 'react'
import tax from '../../../static/Income tax.jpg'
export default function IncomeTax() {
  return (
    <div>
              <div className='h-24  overflow-x-hidden bg-berry'>
            <h2 className='text-center text-white my-4 overline text-xl lg:text-3xl 'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Personal & Corporate Income Tax Services</h2>
        </div> 
          <div className='bg-berry'>
<div class="p-1 items-center justify-center  ">

<div className='mx-4  justify-center items-center appear '>

<p class="mb-3 text-white first-line:uppercase text-xs sm:text-xs md:text-[16px] lg:text-[16px]
first-line:tracking-widest first-letter:text-md sm:first-letter:text-md md:first-letter:text-lg lg:first-letter:text-2xl first-letter:font-bold 
 first-letter:text-white
 first-letter:me-1 first-letter:float-start"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000"> Personal & Corporate Income Tax Services</p>

<p class="text-white text-[16px]"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
IMZI & IHCA  simplifies the complexities of tax compliance with our Personal & Corporate Income Tax Services. We offer a range of tax and VAT planning services to meet the needs of businesses of all sizes and industries. We work closely with our clients to understand their unique tax situation and develop a customized plan to help them achieve their financial goals. Whether you are starting a new business, expanding your existing business, or planning for retirement, we can help you make the most of your tax savings opportunities.

</p>
<p class="text-white text-[16px] my-3"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
We can help you to develop a customized tax plan that takes into account your unique business circumstances and goals. Our tax planning services can help you to: 

</p>

<div class="thumbanail right mx-20"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
        <img src={tax} alt="" />
    </div>
<dl class="max-w-md text-white divide-y divide-gray-200  my-5 text-reveal">
  <div class="flex flex-col pb-3">
     
      <dd class="text-[16px] "data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">Tax planning for businesses and individuals</dd>
  </div>
  <div class="flex flex-col py-3">
      
      <dd class="text-[16px] "data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">Comply with tax &VAT laws and regulations</dd>

  </div>
  <div class="flex flex-col pt-3">
     
     <dd class="text-[16px] "data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">Tax audits and appeals</dd>
 </div>
  <div class="flex flex-col pt-3">
     
      <dd class="text-[16px] "data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">Tax structuring</dd>
  </div>
  <div class="flex flex-col pt-3">
     
     <dd class="text-[16px] "data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">Tax due diligence</dd>
 </div>
 <div class="flex flex-col pt-3">
     
     <dd class="text-[16px] "data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">Improve your cash flow</dd>
 </div>
 <div class="flex flex-col pt-3">
     
     <dd class="text-[16px] "data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">Avoid tax penalties and interest</dd>
 </div>

 <div class="flex flex-col pt-3">
     
     <dd class="text-[16px] "data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">Make informed business decisions</dd>
 </div>

</dl>
<p class="text-white text-[16px] my-5"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
Our dedicated team of tax experts is committed to providing individuals and businesses with strategic tax planning, accurate filings, and proactive advice to optimize financial outcomes. Whether you're an individual seeking to maximize deductions or a corporation navigating intricate tax regulations, our services are tailored to meet your specific needs.

</p>
</div>
</div>

  </div>
    <div className='bg-berry'>
<div class="p-1 items-center justify-center  ">

<div className='mx-4 justify-center items-center appear '>


<p class="mb-3 text-white first-line:uppercase text-xs sm:text-xs md:text-[16px] lg:text-[16px]
first-line:tracking-widest first-letter:text-md sm:first-letter:text-md md:first-letter:text-lg lg:first-letter:text-2xl first-letter:font-bold 
 first-letter:text-white
 first-letter:me-1 first-letter:float-start"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000"> Personal Income Tax Services:</p>
<p class="text-white  text-[16px]"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
A strong personal tax strategy involves much more than simply preparing and filing your tax return. High net worth individuals, business owners, corporate directors and executives often face complex tax scenarios. We help you keep up with changes in tax legislation, develop an effective financial plan and protect your personal and family wealth, as well as your estate’s value. We provide advice on a range of personal tax considerations to ensure you’re meeting your tax obligations while minimizing your exposure.

</p>
<p class="text-white  text-[16px] my-2"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
We guide individuals through the maze of personal income tax regulations, helping maximize deductions and credits to minimize tax liabilities. Our experts stay abreast of changing tax laws to ensure that you benefit from the latest opportunities and remain in compliance with all relevant regulations. From filing routine tax returns to handling complex financial situations, our personalized approach ensures that your unique tax needs are met with precision. 
</p>


</div>
</div>

  </div>
  
  <div className='bg-berry'>
<div class="p-1 items-center justify-center  ">

<div className='mx-4  my-5 justify-center items-center appear '>


<p class="mb-3 text-white first-line:uppercase text-xs sm:text-xs md:text-[16px] lg:text-[16px]
first-line:tracking-widest first-letter:text-md sm:first-letter:text-md md:first-letter:text-lg lg:first-letter:text-2xl first-letter:font-bold 
 first-letter:text-white
 first-letter:me-1 first-letter:float-start"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000"> Corporate Income Tax Services:</p>

<p class="text-white text-[16px]"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
Navigate corporate tax complexities with confidence. Our services cover all aspects of corporate income tax, including strategic planning, compliance, and reporting. We optimize your corporate tax structure to maximize deductions, credits, and incentives, allowing your business to operate efficiently and cost-effectively. Our team provides proactive advice on tax-saving opportunities and ensures timely and accurate filing to meet regulatory requirements.


</p>



</div>
</div>

  </div>
  <div className='bg-berry'>
<div class="p-1 items-center justify-center  ">

<div className='mx-4  justify-center items-center appear '>


<p class="mb-3 text-white first-line:uppercase text-xs sm:text-xs md:text-[16px] lg:text-[16px]
first-line:tracking-widest first-letter:text-md sm:first-letter:text-md md:first-letter:text-lg lg:first-letter:text-2xl first-letter:font-bold 
 first-letter:text-white
 first-letter:me-1 first-letter:float-start"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000"> Tax Planning and Optimization:</p>

<p class="text-white text-[16px]"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
Strategic tax planning is at the core of our services. We work closely with clients to develop customized tax strategies that align with their financial goals and objectives. Our experts identify opportunities for tax optimization, ensuring that you make informed decisions to minimize tax burdens and maximize financial efficiency.

</p>

<p class="text-white text-[16px] my-2"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
Stay ahead of ever-evolving tax regulations with our compliance assurance services. We help individuals and businesses adhere to tax laws, file accurate returns, and navigate any audits or inquiries with confidence.
Choose IMZI & IHCA for Personal & Corporate Income Tax Services that go beyond routine filings. Our commitment is to provide you with personalized, strategic tax solutions that optimize financial outcomes and contribute to your overall financial success.


</p>
</div>
</div>

  </div>
  </div>
  )
}
