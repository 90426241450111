import React from 'react'
import val1 from '../../../static/val.avif'
export default function Valution() {
  return (
    <div>
         <div>
         <div className='h-24  overflow-x-hidden bg-fruit'>
            <h2 className='text-center text-white my-4 overline text-xl lg:text-3xl 'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Valuation & Financial Modeling</h2>
        </div> 
      <div className='flex flex-col lg:flex-row items-center justify-center bg-fruit'>
  <div className='w-full lg:w-1/2 my-10 mx-4'>
    <div class="grid grid-col-2 ">
    <div className='mx-10 thumb'>
  <img className="w-full h-auto max-h-96 rounded-lg" data-aos="zoom-out" data-aos-delay="50"
 data-aos-duration="1000"
    src={val1} alt=""/>
</div>

  
    </div>
  </div>
  <div className='w-4.6 lg:w-1/2 my-10 mx-12 lg:mx-10 justify-end align-middle items-center text-reveal'>

            <p className='mb-3 text-white first-line:uppercase text-xs sm:text-xs md:text-[16px] lg:text-[16px]
first-line:tracking-widest first-letter:text-md sm:first-letter:text-md md:first-letter:text-lg lg:first-letter:text-2xl first-letter:font-bold 
 first-letter:text-white
 first-letter:me-1 first-letter:float-start'data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
   Valuation & Financial Modeling Services
    </p>
    <p className=' text-[16px] text-white'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">IMZI & IHCA delivers comprehensive Valuation & Financial Modeling Services, providing clients with strategic insights and precise analyses to inform critical business decisions. Our seasoned team of financial experts combines industry knowledge with cutting-edge modeling techniques to offer tailored solutions that enhance financial transparency, optimize investment decisions, and support strategic planning. Our Service includes:
    </p>
    <dl class="max-w-md text-white divide-y divide-gray-200  my-5 appear">
    <div class="flex flex-col pb-3">
       
        <dd class="text-[16px] font-semibold"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Business Valuation</dd>
    </div>
    <div class="flex flex-col py-3">
        
        <dd class="text-[16px] font-semibold"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Asset Valuation </dd>
    </div>
    <div class="flex flex-col py-3">
       
        <dd class="text-[16px] font-semibold"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Mergers and Acquisitions (M&A) Modeling</dd>
    </div>
    <div class="flex flex-col py-3">
       
        <dd class="text-[16px] font-semibold"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Project Finance Modeling</dd>
    </div>
</dl>
  </div>
</div>
    </div>
    </div>
  )
}
