import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CustomConsulting from './CustomConsulting';
import IncomeTax from './IncomeTax';
import TaxationService from './TaxationService';
import TransferPricing from './TransferPricing';
import VatConsulting from './VatConsulting';

export default function Taxationindex() {
  return (
    <Routes>
    
           <Route path="/taxationService" element={<TaxationService/>} />
    <Route path="/customconsulting" element={<CustomConsulting/>} />
    <Route path="/incomeTax" element={<IncomeTax/>} />
    <Route path="/transferpricing" element={<TransferPricing/>} />
    <Route path="/vatconsulting" element={<VatConsulting/>} />
    

    </Routes>
  )
}
