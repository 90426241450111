import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CoorporateFinance from './CoorporateFinance';
import Feasibility from './Feasibility';
import Mearges from './Mearges';
import Valution from './Valution';

export default function CorporateIndex() {
  return (
    <Routes>
 
    <Route path="/corporatefinance" element={<CoorporateFinance/>} />
    <Route path="/feasibility" element={<Feasibility/>} />
    <Route path="/mearges" element={<Mearges/>} />
    <Route path="/valuation" element={<Valution/>} />
    

    </Routes>
  )
}
