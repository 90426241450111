import React from 'react'
import ac1 from '../../static/pay4.webp';
export default function Insights() {
  return (
    <>
      <div className='h-24 overflow-x-hidden bg-berry'>
  <h2 className='text-center text-white my-4 overline text-xl lg:text-3xl '>Insights</h2>
</div>

<div className='flex items-center justify-center bg-berry drop-shadow-lg'>
  <div className='w-5/6 sm:w-3/4 md:w-2/3 lg:w-2/3 xl:w-2/3 bg-berry rounded-lg mt-4 mb-4'>
    <figure className='relative bg-berry'>
      <a href="#" className=''>
        <img
          className='rounded-lg w-full my-3 cursor-pointer filter blur-0 hover:blur-sm'
          src={ac1}
          alt="image description"
        />
      </a>
      <figcaption className='absolute px-4 text-lg text-white bottom-6 text-reveal'>
        <p className='text-xl my-2'>Construction going on....</p>
      </figcaption>
    </figure>
  </div>
</div>

    </>
  )
}
