import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AuditandAssurance from './AuditandAssurance';
import AuditService from './AuditService';
import Buisnessprocess from './Buisnessprocess';
import ControlService from './ControlService';
import CorporateFinance from '../Advisory/CorporateFinance';
import CorporateGovernance from './CorporateGovernance';
import InformationAudit from './InformationAudit';
import InventionandForensic from './InventionandForensic';

export default function AuditIndex() {
  return (
    <Routes>
  
    <Route path="/auditassurance" element={<AuditandAssurance />} />
    <Route path="/auditservice" element={<AuditService/>} />
    <Route path="/auditbuisnessprocess" element={<Buisnessprocess />} />
    <Route path="/controlservice" element={<ControlService />} />
    <Route path="/corporategovernce" element={<CorporateGovernance/>} />
    <Route path="/informationaudit" element={<InformationAudit />} />
    <Route path="/inventional" element={<InventionandForensic/>} />

    </Routes>
  )
}
