import React from 'react'
import './css/controlService.css'
import saudit from '../../../static/satutaryaudit.png'
import audit from '../../../static/audit1.png'
import a from '../../../static/audit.jpg'
import internalaudit from '../../../static/internal audit.jpg'
export default function AuditService() {
  return (
    <>
        <div className='h-24  overflow-x-hidden bg-cyan'>
            <h2 className='text-center text-white my-4 overline text-xl lg:text-3xl 'data-aos="zoom-in"data-aos-delay="50"
     data-aos-duration="1000">Audit Services</h2>
        </div> 
       <div class="p-1 items-center justify-center bg-cyan ">

<div className='mx-4   justify-between flex items-center text-reveal '>

 <div >
<p class="mb-3 text-white first-line:uppercase text-xs sm:text-xs md:text-[16px] lg:text-[16px]
first-line:tracking-widest first-letter:text-md sm:first-letter:text-md md:first-letter:text-lg lg:first-letter:text-2xl first-letter:font-bold 
 first-letter:text-white
 first-letter:me-1 first-letter:float-start"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000"> Audit Services</p>


<div class="thumbanail right "data-aos="zoom-in-up"data-aos-delay="50"
     data-aos-duration="1000">
        <img src={a} alt="" />
    </div>
    
    <p className=' text-[16px]  text-white'data-aos="zoom-in"data-aos-delay="50"
     data-aos-duration="1000">
    IMZI & IHCA recognizes that the quality of the audit is dependent on the experience, process and continuity of the team who will perform these services. But more importantly we recognize that for any professional service relationship to be successful you must be dealing with people you can work with.  
We will put forward a team who we believe you will be comfortable with and can work with you in a spirit of cooperation. 
We want you to look forward to our visits as an opportunity to benefit from the relationship rather than dread 
the thought of another audit visit. At IMZI & IHCA we have a stable and dynamic audit team. 



  </p>
</div>

 </div>

 <div class="c-container  appear">

<div className=" ">


<p class="mb-3 text-white first-line:uppercase text-xs sm:text-xs md:text-[16px] lg:text-[16px]
first-line:tracking-widest first-letter:text-md sm:first-letter:text-md md:first-letter:text-lg lg:first-letter:text-2xl first-letter:font-bold 
 first-letter:text-white
 first-letter:me-1 first-letter:float-start"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000"> Internal Audit:</p>

<p className='text-[16px] text-left'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
Our Internal Audit services are tailored to provide an independent and objective evaluation of your internal controls, risk management processes, and overall governance framework. We collaborate closely with your team to identify areas for improvement, enhance operational effectiveness, and mitigate potential risks. By leveraging cutting-edge audit methodologies, we empower your organization to make informed decisions and foster a culture of continuous improvement. Our professional resources will assist your organization to accomplish objectives by bringing a disciplined, systematic approach to evaluate and improve the effectiveness of the company’s risk management, control, reporting and governance processes. 


</p>
<p class="text-white text-[16px] text-left"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
We will provide assurance in the following areas:

</p>


<div class="thumbanail right h-72 "data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
        <img src={internalaudit} alt="" />
    </div>
<dl class="max-w-md text-white divide-y divide-gray-200  my-4 text-reveal"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
    <div class="flex flex-col pb-3"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
       
        <dd class="text-[16px] ">Compliance with existing laws, regulations, policies & procedures</dd>
    </div>
    <div class="flex flex-col py-3"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
        
        <dd class="text-[16px] "data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Accomplishment of established goals and objectives</dd>
    </div>
    <div class="flex flex-col pt-3"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
       
        <dd class="text-[16px] ">Reliability & integrity of information</dd>
    </div>
    <div class="flex flex-col pt-3"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
       
       <dd class="text-[16px] "data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Efficient & effective use of resources</dd>
   </div>
   <div class="flex flex-col pt-3">
       
       <dd class="text-[16px] "data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Safeguarding of assets</dd>
   </div>

</dl>



<p class="text-white text-[16px] text-left"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
At IMZI & IHCA , our expert resources are very much skilled on Risk Based Audit methodology and they will help to adopt this modern concept in your orgnaization:

</p>





<p className=' text-[16px] text-left'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">

The Internal Audit Process (IAP) methodology has been developed based on the modern global audit standards, general good practice for business process audit. This methodology will be tailored based on your business environment to fit and to provide a framework that can be consistently applied for our clients business. 


</p>
<div class="appear flex justify-center items-center 
transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 " data-aos="zoom-in-up"data-aos-delay="50"
data-aos-duration="1000">
  <img class="object-fill h-60 w-4/5 rounded-2xl " src=
  {audit} alt="" />
</div>



      </div>
 
  
</div>
<div class="c-container  appear">

<div className=" ">


<p class="mb-3 text-white first-line:uppercase text-xs sm:text-xs md:text-[16px] lg:text-[16px]
first-line:tracking-widest first-letter:text-md sm:first-letter:text-md md:first-letter:text-lg lg:first-letter:text-2xl first-letter:font-bold 
 first-letter:text-white
 first-letter:me-1 first-letter:float-start" data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000"> Statutory Audit:</p>

<p className='text-[16px] text-left'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
Navigating the complex landscape of regulatory compliance is crucial for sustaining trust and credibility. Our Statutory Audit services are designed to meet legal requirements while adding value to your financial reporting. With a meticulous approach, we conduct in-depth examinations of financial statements, ensuring accuracy, transparency, and compliance with applicable laws and accounting standards. Our goal is to instill confidence among stakeholders, including investors, regulators, and business partners.


</p>







<p className=' text-[16px] text-left'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">

The Internal Audit Process (IAP) methodology has been developed based on the modern global audit standards, general good practice for business process audit. This methodology will be tailored based on your business environment to fit and to provide a framework that can be consistently applied for our clients business. 


</p>
<div class="appear flex justify-center items-center transition
 ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 my-20" data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
  <img class="object-fill h-auto w-4/5 rounded-2xl shadow-2xl" src={saudit} alt="" />
</div>



      </div>
  
  
</div>
 </div>
    </>
  )
}
