import React from 'react'
import bpo from '../../../static/bsnsproceesoutsourcing.webp'
export default function BuisnuessOutsourcing() {
  return (
    <div>
             <div className='h-24  overflow-x-hidden bg-berry'>
            <h2 className='text-center text-white my-4 overline text-xl lg:text-3xl 'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Business Process
             Outsourcing</h2>
        </div> 
      <div className='bg-berry'>
 <div class="p-1 items-center justify-center  ">
 
<div className='mx-5  justify-center items-center appear '>

 
<p class="mb-3 text-white first-line:uppercase text-xs sm:text-xs md:text-[16px] lg:text-[16px]
first-line:tracking-widest first-letter:text-md sm:first-letter:text-md md:first-letter:text-lg lg:first-letter:text-2xl first-letter:font-bold 
 first-letter:text-white
 first-letter:me-1 first-letter:float-start"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000"> Business process outsourcing</p>
<p class="text-white  text-[16px]"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
At IMZI & IHCA, We have a proven track record of helping businesses achieve their goals by streamlining operations, improving efficiency, and reducing costs. We are confident that we can provide you with the expertise and resources you need to succeed.

</p>


 </div>
 </div>

    </div>
    <div className='bg-berry'>
 <div class="p-1 items-center justify-center  ">
 
<div className='mx-5 my-3 justify-center items-center appear '>

 

<p class="text-white text-left text-[16px]"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
IMZI & IHCA is a global provider of BPO services with a team of experienced professionals who are dedicated to helping businesses achieve their goals. We offer a wide range of BPO services, including:

</p>


<div class="thumbanail right h-2/3 my-2 "data-aos="zoom-out"data-aos-delay="50"
 data-aos-duration="1000">
        <img src={bpo} alt="" />
    </div>

    <ul class="  space-y-4 text-left text-white text-[16px]">
    <li class="flex items-center space-x-3 rtl:space-x-reverse"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
         <svg class="flex-shrink-0 w-3.5 h-3.5 text-cyan " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
         </svg>
        <span>Finance and accounting outsourcing</span>
    </li>
    <li class="flex items-center space-x-3 rtl:space-x-reverse"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
         <svg class="flex-shrink-0 w-3.5 h-3.5 text-cyan " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
         </svg>
        <span>Internal Audit & control function</span>
    </li>
    <li class="flex items-center space-x-3 rtl:space-x-reverse"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
         <svg class="flex-shrink-0 w-3.5 h-3.5 text-cyan " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
         </svg>
        <span>Enterprise risk management</span>
    </li>
    <li class="flex items-center space-x-3 rtl:space-x-reverse"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
         <svg class="flex-shrink-0 w-3.5 h-3.5 text-cyan " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
         </svg>
        <span>Corporate governance</span>
    </li>
    <li class="flex items-center space-x-3 rtl:space-x-reverse"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
         <svg class="flex-shrink-0 w-3.5 h-3.5 text-cyan " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
         </svg>
        <span>Financial planning and budgeting</span>
    </li>
    <li class="flex items-center space-x-3 rtl:space-x-reverse"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
         <svg class="flex-shrink-0 w-3.5 h-3.5 text-cyan " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
         </svg>
        <span>Business root cause analysis</span>
    </li>
    <li class="flex items-center space-x-3 rtl:space-x-reverse"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
         <svg class="flex-shrink-0 w-3.5 h-3.5 text-cyan " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
         </svg>
        <span>Back-office outsourcing</span>
    </li>
    <li class="flex items-center space-x-3 rtl:space-x-reverse"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
         <svg class="flex-shrink-0 w-3.5 h-3.5 text-cyan " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
         </svg>
        <span>IT outsourcing</span>
    </li>
    <li class="flex items-center space-x-3 rtl:space-x-reverse"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
         <svg class="flex-shrink-0 w-3.5 h-3.5 text-cyan " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
         </svg>
        <span>Human resources outsourcing</span>
    </li>
</ul>


 </div>
 </div>

    </div>
    <div className='bg-berry'>
 <div class="p-1 items-center justify-center  ">
 
<div className='mx-5 justify-center items-center appear '>

 

<p class="text-white text-left text-[16px]"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
We are committed to providing our clients with high-quality, scalable solutions that meet their evolving needs. We are also committed to building long-term partnerships with our clients and providing them with the support they need to succeed.



</p>
<p class="text-white text-left text-[16px] my-3"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
We understand that businesses face a number of challenges, including:



</p>

<ul class=" my-3 space-y-4 text-left text-white text-[16px]">
    <li class="flex items-center space-x-3 rtl:space-x-reverse"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
         <svg class="flex-shrink-0 w-3.5 h-3.5 text-cyan " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
         </svg>
        <span>Rising costs</span>
    </li>
    <li class="flex items-center space-x-3 rtl:space-x-reverse"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
         <svg class="flex-shrink-0 w-3.5 h-3.5 text-cyan " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
         </svg>
        <span>Increasing complexity</span>
    </li>
    <li class="flex items-center space-x-3 rtl:space-x-reverse"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
         <svg class="flex-shrink-0 w-3.5 h-3.5 text-cyan " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
         </svg>
        <span>The need to focus on core competencies</span>
    </li>



</ul>

 </div>
 </div>

    </div>
    <div className='bg-berry'>
 <div class="p-1 items-center justify-center  ">
 
<div className='mx-5 justify-center items-center appear '>

 

<p class="text-white text-left text-[16px]"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
IMZI & IHCA can help businesses address these challenges and achieve their goals. By outsourcing non-core business processes to a trusted provider, businesses can:

</p>
<ul class=" my-3 space-y-4 text-left text-white text-[16px]">
    <li class="flex items-center space-x-3 rtl:space-x-reverse"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
         <svg class="flex-shrink-0 w-3.5 h-3.5 text-cyan " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
         </svg>
        <span>Reduce costs</span>
    </li>
    <li class="flex items-center space-x-3 rtl:space-x-reverse"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
         <svg class="flex-shrink-0 w-3.5 h-3.5 text-cyan " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
         </svg>
        <span>Improve efficiency</span>
    </li>
    <li class="flex items-center space-x-3 rtl:space-x-reverse"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
         <svg class="flex-shrink-0 w-3.5 h-3.5 text-cyan " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
         </svg>
        <span>Free up resources to focus on core competencies</span>
    </li>

    <li class="flex items-center space-x-3 rtl:space-x-reverse"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
         <svg class="flex-shrink-0 w-3.5 h-3.5 text-cyan " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
         </svg>
        <span>Gain access to specialized expertise</span>
    </li>
    <li class="flex items-center space-x-3 rtl:space-x-reverse"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
         <svg class="flex-shrink-0 w-3.5 h-3.5 text-cyan " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
         </svg>
        <span>Improve scalability</span>
    </li>
    <li class="flex items-center space-x-3 rtl:space-x-reverse"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
         <svg class="flex-shrink-0 w-3.5 h-3.5 text-cyan " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
         </svg>
        <span>Enhance risk management</span>
    </li>
</ul>
<p class="text-white text-left text-[16px]"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
We invite you to contact us to learn more about how we can help you achieve your business goals. We are confident that we can provide you with the expertise and resources you need to succeed.

</p>
 </div>
 </div>

    </div>
    </div>
  )
}
