import React from 'react'
import { Link } from 'react-router-dom'
import co1 from '../../../static/valuation.webp'
import co2 from '../../../static/feasible.avif'
import co3 from '../../../static/co2.avif'
export default function CoorporateFinance() {
  return (
    <div>
      
  <div className=' bg-berry '>
  <div className='h-24 bg-berry overflow-x-hidden '>
            <h2 className='text-center my-4 overline  text-xl lg:text-3xl
            text-white
            'data-aos="zoom-in"data-aos-delay="50"
            data-aos-duration="1000">Corporate Finance </h2>
        </div>
        <div class="p-1 flex flex-wrap items-center justify-center  ">
        <div class="flex justify-center items-center my-10 mx-10">

    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">

<Link  to='/valuation'data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
    <div class="w-full max-w-sm bg-white  rounded-lg shadow img-cards">
    <figure class="relative max-w-sm ">
  <a href="#">
    <img class="rounded-lg transition-all duration-300 cursor-pointer h-96 w-96
    filter blur-0 hover:blur-sm"
     src={co1}
     alt="image description"/>
  </a>
  <figcaption class="absolute px-4 text-xl   text-center text-white bottom-6 text-reveal">
      <p className='text-center '>Valuation & Financial modelling </p>
  </figcaption>
</figure>
</div>
</Link>
<Link to='/feasibility'data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
<div class="w-full max-w-sm bg-white  rounded-lg shadow img-cards">
    <figure class="relative max-w-sm ">
  <a href="#">
    <img class="rounded-lg transition-all duration-300 cursor-pointer h-96 w-96
    filter blur-0 hover:blur-sm"
    src={co2}
     alt="image description"/>
  </a>
  <figcaption class="absolute px-4 text-xl text-white bottom-6 text-reveal">
      <p>Feasibility Study & Due Diligence</p>
  </figcaption>
</figure>
</div>  
</Link>
<Link to='/mearges'data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
  <div class="w-full max-w-sm bg-white rounded-lg shadow img-cards">
    <figure class="relative max-w-sm ">
  <a href="#">
    <img class="rounded-lg transition-all duration-300 cursor-pointer h-96 w-96
    filter blur-0 hover:blur-sm"
    src={co3}
     alt="image description"/>
  </a>
  <figcaption class="absolute px-4 text-xl text-white bottom-6 text-reveal">
      <p>Mergers & Acquisitions</p>
  </figcaption>
</figure>
</div> 
</Link>

    
    </div>
</div>



        </div>
        </div>
    </div>
  )
}
