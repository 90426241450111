import React from 'react'

export default function PrivacyPolicy() {
  return (
    <div>
                <div className='h-24  overflow-x-hidden bg-berry'>
            <h2 className='text-center text-white my-4 overline text-xl lg:text-3xl '>Privacy Policy</h2>
        </div> 
        <div className='h-screen bg-berry mx-5 overflow-y-auto'>
<p className='text-lg text-left text-white'>
Welcome to IMZI & IHCA! We are committed to safeguarding the privacy of our clients, partners, and website visitors.
 This Privacy Policy outlines how IMZI & IHCA collects, uses, and protects your personal information. By accessing our website or utilizing our services, you acknowledge and agree to the terms outlined in this policy.


 IMZI & IHCA may collect personal information, including but not limited to names, contact details, and professional information, to provide you with our services effectively.


For statistical purposes and to enhance user experience, IMZI & IHCA may collect non-personal information, such as website usage statistics, through the use of cookies and similar technologies.
IMZI & IHCA uses the collected information for the following purposes:
Providing audit, accounting, and related services to our clients.
Sending relevant updates, newsletters, and information about our services.
Analyzing data to enhance our website, services, and client experiences.
Meeting legal and regulatory obligations.

IMZI & IHCA employs industry-standard security measures to
 protect your information from unauthorized access, disclosure, alteration, or destruction.
  However, no data transmission over the internet can be guaranteed to be completely secure.
   Therefore, while we strive to protect your information, we cannot ensure or warrant the
    security of any information you transmit to us.
    IMZI & IHCA does not sell, rent, or share your personal information 
    with third parties unless required by law or with your explicit consent.
    IMZI & IHCA does not knowingly collect personal information from children. 
    Our services are intended for individuals who are at least 18 years old or the age of 
    legal majority in their jurisdiction.
  Our website may contain links to external sites. We are not responsible for the content or privacy practices of these sites. We encourage users to review 
        the privacy policies of any external websites they visit.
        IMZI & IHCA reserves the right to update or modify this 
        Privacy Policy at any time without prior notice. We encourage you to review this policy 
        periodically for any changes.
    If you have questions or concerns about this Privacy Policy, 
        please contact us at <a className='text-cyan' >info@imzi-ca.com </a>

 By continuing to use our website or services, you consent to the terms outlined in this Privacy Policy. 
Thank you for choosing IMZI & IHCA.</p>
        </div>
    </div>
  )
}
