import React from 'react'
import { Link } from 'react-router-dom'
import t1 from '../../../static/transferpricing.webp'
import t2 from '../../../static/tranfer1.webp'
import t3 from '../../../static/t3.webp'
import t4 from '../../../static/t4.webp'
export default function TaxationService() {
  return (
    <div>
      
          <div className=' bg-cyan'>
  <div className='h-24 bg-cyan overflow-x-hidden 'data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
            <h2 className='text-center my-4 overline text-xl lg:text-3xl
            text-white
            '>Taxation Service</h2>
        </div>
        <div class="p-1 flex flex-wrap items-center justify-center  ">
        <div class="flex justify-center items-center my-10 mx-10">

    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-10">

<Link to='/incomeTax'data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
    <div class="w-full max-w-sm bg-white  rounded-lg shadow img-cards">
    <figure class="relative max-w-sm ">
  <a href="#" className='acnt'>
    <img class="rounded-lg  cursor-pointer h-96 w-96
   "
     src={t1}
     alt="image description"/>
  </a>
  <figcaption class="absolute px-4 text-xl  text-white bottom-6 text-reveal">
      <p className=''>Personal & Corporate Income Tax</p>
  </figcaption>
</figure>
</div>
</Link>
<Link to='/transferpricing'data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">

<div class="w-full max-w-sm bg-white  rounded-lg shadow img-cards">
 <figure class="relative max-w-sm ">
<a href="#" className='acnt'>
 <img class="rounded-lg transition-all duration-300 cursor-pointer h-96 w-96
"
src={t2}
  alt="image description"/>
</a>
<figcaption class="absolute px-4 text-xl text-white bottom-6 text-reveal">
   <p>	Transfer Pricing</p>
</figcaption>
</figure>
</div>
</Link>     
<Link to='/vatconsulting'data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">

    <div class="w-full max-w-sm bg-white  rounded-lg shadow img-cards">
    <figure class="relative max-w-sm ">
  <a href="#" className='acnt'>
    <img class="rounded-lg transition-all duration-300 cursor-pointer h-96 w-96
   "
   src={t3}
     alt="image description"/>
  </a>
  <figcaption class="absolute px-4 text-xl text-white bottom-6 text-reveal">
      <p>VAT Consulting </p>
  </figcaption>
</figure>
</div>   
</Link>
<Link to='/customconsulting'data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
 <div class="w-full max-w-sm bg-white  rounded-lg shadow .img-cards">
    <figure class="relative max-w-sm ">
  <a href="#" className='acnt'>
    <img class="rounded-lg transition-all duration-300 cursor-pointer h-96 w-96
    filter blur-0 hover:blur-sm"
    src={t4}
     alt="image description"/>
  </a>
  <figcaption class="absolute px-4 text-xl text-white bottom-6 text-reveal">
      <p>Customs Consulting</p>
  </figcaption>
</figure>
</div> 
</Link>
    
    </div>
</div>


        </div>
        </div>
    </div>
  )
}
