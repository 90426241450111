import React from 'react'

export default function Bookeeping() {
  return (
    <div>
          <div className='h-24  overflow-x-hidden bg-cyan'>
            <h2 className='text-center text-white my-4 overline text-xl lg:text-3xl 'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Bookkeeping & Cloud Accounting Services

</h2>
        </div> 
          <div className='bg-cyan'>
 <div class="p-1 items-center justify-center  ">
 <div class="flex justify-center items-center mt-6 my-10 ">
  
  <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mx-10 sm:mx-10 md:mx-10 lg:mx-44"data-aos="zoom-out"data-aos-delay="50"
 data-aos-duration="1000">
     

  <div class=" justify-center items-center w-full max-w-sm bg-white  rounded-lg shadow img-cards">
  <figure class="relative max-w-sm ">
<a href="#">
  <img class="rounded-lg transition-all duration-300 cursor-pointer h-96 w-96
  filter blur-0 hover:blur-sm"
   src="https://media.istockphoto.com/id/1374988991/photo/co-working-team-meeting-concept-man-using-voip-headset-with-digital-tablet-and-latop-computer.jpg?s=612x612&w=0&k=20&c=1WPEanu4Xv6BBkW85yQb_HkoqWeITdtplNA1IazlHjc="/>
</a>

</figure>
</div> 
   <div class="hidden sm:block  md:hidden lg:block w-full max-w-sm bg-white  rounded-lg shadow .img-cards">
  <figure class="relative max-w-sm ">
<a href="#">
  <img class="rounded-lg transition-all duration-300 cursor-pointer h-96 w-96
  filter blur-0 hover:blur-sm"
   src="https://media.istockphoto.com/id/1611330716/photo/education-learning-concept-book-in-library-with-old-open-textbook-stack-piles-of-literature.jpg?s=612x612&w=0&k=20&c=2W_w3GqKL-xWx-eJ0eY9U2aDLKBuO2fDQ2M4MxQM7IU=" 
   alt="image description"/>
</a>

</figure>
</div>    
<div class="w-full max-w-sm hidden md:block lg:block  border-fruit rounded-lg shadow img-cards">
  <figure class="relative max-w-sm ">
<a href="#">
  <img class="rounded-lg transition-all duration-300 cursor-pointer h-96 w-96
  filter blur-0 hover:blur-sm"
   src="https://media.istockphoto.com/id/473486934/photo/coffee-cup-at-office-over-financial-papers-and-agenda-coffee-bre.jpg?s=612x612&w=0&k=20&c=6SapKhY0DzOWbHidjoJk0HXj5lbMfUX44ZGsAJ7xQME=" 
   alt="image description"/>
</a>

</figure>
</div>


      
  </div>


</div>
<div className='mx-4 my-20 justify-center items-center appear '>

 
<p class="mb-3 text-white first-line:uppercase text-xs sm:text-xs md:text-[16px] lg:text-[16px]
first-line:tracking-widest first-letter:text-md sm:first-letter:text-md md:first-letter:text-lg lg:first-letter:text-2xl first-letter:font-bold 
 first-letter:text-white
 first-letter:me-1 first-letter:float-start"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000"> Bookkeeping & Cloud Accounting Services</p>

<p class="mb-3 text-[16px] text-white "data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">IMZI & IHCA offers cutting-edge Bookkeeping & Cloud Accounting Services designed to streamline your financial management processes, enhance accuracy, and provide real-time insights into your business's financial health. Our dedicated team of accounting professionals leverages the power of cloud technology to deliver efficient, secure, and customized solutions tailored to meet the unique needs of your organization.

.</p>
<p class="mb-3 text-[16px] text-white"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Embrace the future of financial management with our Cloud Accounting Services. Access your financial data securely anytime, anywhere, and enjoy the flexibility of real-time collaboration with our team. Leave the complexities of bookkeeping to us. Our meticulous approach ensures accurate recording of financial transactions, facilitating precise reporting, and supporting informed decision-making.</p>

<p class="mb-3 text-[16px] text-white "data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
Gain a comprehensive view of your organization's financial performance with our customized reporting services.  Ensure your accounting and bookkeeping is always on, always accurate, always accessible. Ease is a digital accounting and bookkeeping solution that transforms real-time information into real-world business insights. We also provide services
.</p>

<dl class="max-w-md text-white divide-y divide-gray-200  my-5 text-reveal"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
    <div class="flex flex-col pb-3">
       
        <dd class="text-[16px] "data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Project based outsourcing.</dd>
    </div>
    <div class="flex flex-col py-3">
        
        <dd class="text-[16px] "data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Staff augmentation outsourcing</dd>
    </div>
    <div class="flex flex-col pt-3">
       
        <dd class="text-[16px] "data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">	Managed service outsourcing</dd>
    </div>
</dl>



 </div>
 </div>

    </div>
    </div>
  )
}
